var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "aptamer-favorite-container" }, [
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _vm._l(_vm.records, function (item, i) {
          return _c("div", { key: i, staticClass: "item-container" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "figure" }, [
                _c("img", { attrs: { src: item.cover } }),
              ]),
              _c("div", { staticClass: "location" }, [
                _c(
                  "a",
                  {
                    staticClass: "entry entry-title",
                    attrs: { href: `/patent/${item.patentID}` },
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                ),
                _c("div", { staticClass: "entry" }, [
                  _c("div", [
                    _vm._v(" 发明人："),
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(_vm._s(item.inventor)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", [
                    _vm._v(" 申请号："),
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(_vm._s(item.applicationNumber)),
                    ]),
                  ]),
                  _c("div", { staticStyle: { "margin-left": "16px" } }, [
                    _vm._v(" 专利权人："),
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(_vm._s(item.licensor)),
                    ]),
                  ]),
                  _c("div", { staticStyle: { "margin-left": "16px" } }, [
                    _vm._v(" 专利状态："),
                    _c("span", { staticStyle: { color: "#3463ea" } }, [
                      _vm._v(_vm._s(item.statusDescription)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "entry" }, [
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("摘要："),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "textOmitted",
                      staticStyle: { width: "90%" },
                    },
                    [_vm._v(" " + _vm._s(item.abstract) + " ")]
                  ),
                ]),
              ]),
            ]),
          ])
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pager.index,
                "page-sizes": [10, 25, 50],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, jumper, sizes",
                total: _vm.total,
              },
              on: { "current-change": _vm.query, "size-change": _vm.query },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }