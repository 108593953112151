<template>
	<div class="aptamer-favorite-container">
		<div class="item-list">
			<div class="item-container" v-for="(item, i) in records" :key="i">
				<div class="item">
					<div class="figure">
						<img :src="item.cover" />
					</div>
					<div class="location">
						<a :href="`/patent/${item.patentID}`" class="entry entry-title">
							{{ item.title }}
						</a>
						<div class="entry">
							<div>
								发明人：<span style="color: #3463ea;">{{ item.inventor }}</span>
							</div>
						</div>
						<div class="entry">
							<div>
								申请号：<span style="color: #3463ea;">{{
									item.applicationNumber
								}}</span>
							</div>
							<div style="margin-left: 16px;">
								专利权人：<span style="color: #3463ea;">{{
									item.licensor
								}}</span>
							</div>
							<div style="margin-left: 16px;">
								专利状态：<span style="color: #3463ea;">{{
									item.statusDescription
								}}</span>
							</div>
						</div>
						<div class="entry">
							<div style="width: 10%;">摘要：</div>
							<div class="textOmitted" style="width: 90%;">
								{{ item.abstract }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page="pager.index"
					:page-sizes="[10, 25, 50]"
					:page-size="pager.size"
					layout="prev, pager, next, jumper, sizes"
					@current-change="query"
					@size-change="query"
					:total="total"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../../../../api"

export default {
	data() {
		return {
			api: new UserCenterApi(ApiClient.instance),
			records: [],
			total: 0,
			pager: {
				index: 1,
				size: 10
			}
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		query() {
			this.api.apiUserCenterFavoritesGet(
				1,
				{
					index: this.pager.index,
					size: this.pager.size
				},
				(e, data, resp) => {
					data = resp.body
					if (data.success) {
						this.records = data.result.records.map((item) => {
							var rec = item
							if (rec.cover) {
								rec.cover = process.env.VUE_APP_IMG_API + rec.cover
							}
							return rec
						})
						this.total = data.result.total
						console.log(this.records)
					}
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
.textOmitted {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.item-list {
	width: 100%;
	a {
		text-decoration: none;
	}

	.item-container {
		width: 100%;
		display: inline-block;
		background-color: #ffffff;
		margin-top: 16px;

		.item {
			display: flex;
			margin: 16px 23px;

			.figure {
				width: 133px;
				height: 100%;
				border: 1px solid;
				// margin: auto;
				img {
					max-width: 131px;
					height: auto;
				}
			}

			.location {
				position: relative;
				.c-font-size-16();
				line-height: 20px;
				flex-direction: row;
				text-align: left;
				width: 100%;
				padding-left: 1rem;

				.entry {
					display: flex;
					flex-direction: row;
					margin-right: 20px;
					margin-bottom: 12px;
					color: #555555;
					font-weight: 400;
					.value {
						margin-left: 6px;
					}
				}

				.entry-title {
					font-size: 20px;
					color: #3463ea;
					margin-bottom: 20px;
				}

				.entry-cited {
					position: absolute;
					display: flex;
					top: 0rem;
					right: 0rem;
					color: #3463ea;
				}
			}
		}
	}

	.pagination {
		display: flex;
		justify-content: center;
		margin: 10px auto;
	}
}
</style>
